
import Profile3 from "../assets/img/team/profile-picture-3.jpg";
export default Helper=>{

};

export const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
}

export const userAvatar = userName=>{
    if(userName === null){
        return Profile3;
    }
    let spli = userName.split(' ');
    let initials = "";
    spli.forEach(element => {
        if(initials.length <2){
        initials += element.substring(0,1).toUpperCase();
        }
    });

    let path = "https://via.placeholder.com/150/000000/FFFFFF/?text="+ initials;
    return path;
}

export const commandsAvailable = deviceModel =>{
    let commands= []
    switch(deviceModel){
        case "GT06N":
            commands= [
                {
                    Title: "Estatus",
                    Description: "Envia mensaje de estatus al dispositivo",
                    DescriptionalLabel: "Envie <b>status#</b> al número <b>{NUMBER}</b>",
                    Command : "status#"
                },
                {
                    Title: "Ubicación",
                    Description: "Solicita la información del dispositivo",
                    DescriptionalLabel: "Envie <b>where#</b> al número <b>{NUMBER}</b>",
                    Command : "where#"
                },
                {
                    Title: "Activar alarma de vibración",
                    Description : 'Activa la notificación de alerta',
                    DescriptionalLabel: 'Envie <b>SENALM,ON,2#</b> al número <b>{NUMBER}</b>',
                    Command: "SENALM,ON,2#"
                },
                {
                    Title: "Desactivar alarma de vibración",
                    Description : 'Desactiva la notificación de alerta',
                    DescriptionalLabel: 'Envie <b>SENALM,ON#</b> al número <b>{NUMBER}</b>',
                    Command: "SENALM,OFF#"
                },

                {
                    Title: "Desactivar baja bateria",
                    Description : 'Desactivar la alerta de baja bateria',
                    DescriptionalLabel: 'Envie <b>BATALM,OFF#</b> al número <b>{NUMBER}</b>',
                    Command: "BATALM,OFF#"
                },
                {
                    Title: "Activar baja bateria",
                    Description : 'Activa la notificación de baja bateria',
                    DescriptionalLabel: 'Envie <b>BATALM,ON,2#</b> al número <b>{NUMBER}</b>',
                    Command: "BATALM,ON,2#"
                },

                {
                    Title: "Desactivar alarma de movmiento",
                    Description : 'Desactiva la alarma de movimiento',
                    DescriptionalLabel: 'Envie <b>MOVING,OFF#</b> al número <b>{NUMBER}</b>',
                    Command: "MOVING,OFF#"
                },
                {
                    Title: "Activa alarma de movmiento",
                    Description : 'Activa la notificación de movimiento',
                    DescriptionalLabel: 'Envie <b>MOVING,ON,200,1#</b> al número <b>{NUMBER}</b>',
                    Command: "MOVING,ON,200,1#"
                }
                ,

                {
                    Title: "Bloquear encendido",
                    Description : 'Bloquear que el dispositivo pueda encender',
                    DescriptionalLabel: 'Envie <b>RELAY,1#</b> al número <b>{NUMBER}</b>',
                    Command: "RELAY,1#"
                },
                {
                    Title: "Permitir encendido",
                    Description : 'Permitir que el dispositivo pueda encender',
                    DescriptionalLabel: 'Envie <b>RELAY,0#</b> al número <b>{NUMBER}</b>',
                    Command: "RELAY,0#"
                }


            ]
            break;
    }

    return commands;
}