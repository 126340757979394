import { Card, Row, Col,Dropdown } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import API from "../../common/API";
import AddEditGeoFence from "./AddEditGeofence";

const Geofences = props => {
    const [selectedGeofence, setSelectedGeofence] = useState(null);
    const [showAddEditGeofence, setShowAddEditGeofence] = useState(false);
    const [state, setState] = useState({

        Geofences: [],
       
    });


    const toggleAddEditGeofence = () => {
        setShowAddEditGeofence(!showAddEditGeofence);
       
        
    }
    const selectGeofence = item => {
        state.UI.GeofenceSelected.set(item);
        toggleAddEditGeofence();
    }

    const getGeofences = async () => {
        let request = await API.getAction("tracker/geofences", null, null);
        console.log("Response geofences request", request);
        if (request.data.status === "ok") {

            setState({
                Geofences : request.data.response
            })

        }
    }

    useEffect(() => {
        getGeofences();
    }, [])


    return (<>
        {showAddEditGeofence ?
            <AddEditGeoFence toggle={toggleAddEditGeofence} show={showAddEditGeofence} model={selectedGeofence} /> :
            null}
        <Card className="shadow-smm mb-2">
            <Card.Header className="py-1">
                <Row>
                    <Col>
                        <Card.Title>Geofences</Card.Title>
                    </Col>
                    <Col className="text-right">
                        <button className="btn btn-sm btn-primary ml-auto" onClick={() => toggleAddEditGeofence()}>
                            <i className="fa fa-plus" />
                        </button>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body >
                {state.Geofences.map((item, index) => {
                    return (<Row key={"geofence_" + index} >
                        <Col>
                            <b>{item.name}</b>
                            <br />
                            <small className="text-muted">{item.description}</small>
                        </Col>
                        <Col className="ml-auto text-right">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic" size="sm">
                                    <i className="fa fa-ellipsis-v" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item as="button" onClick={() => toggleAddEditGeofence(item)}>Edit</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>

                        <Col xs={12} >
                            <hr className="my-1" />
                        </Col>
                    </Row>)
                })}
            </Card.Body>
        </Card>
    </>)
}

export default Geofences;