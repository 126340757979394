
export const Routes = {
    // pages
    Home: { path: "/" },
    Dashboard: { path: "/Dashboard" },
    Settings: {path:"/settings"},

    Devices: {path:"/devices"},
    DevicesDetails: {path:"/devices/details/:id", clean: "/devices/details/"},

    Geofences: {path:"/geofences"},

    Users: {path:"/users"},
    ProjectDetails: {path:"/Projects/detail/:id", clean:"/Projects/Detail/"},
    
    NotFound: { path: "/examples/NotFound" },
    ServerError: { path: "/examples/ServerError" },
    
    Profile: {path:"/profile"},
    SignIn: {path:"/signin"},
    Register: {path:"/Register"},
    ForgotPassword: {path: "/ForgotPassword"},
    ResetPassword: {path: "/ResetPassword"},
    
  PasswordRecovery: { path: "/PasswordRecovery/:id" },
    Admin: {path: "/Admin"},
};