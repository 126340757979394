import { Card, Row, Col, Container } from "react-bootstrap";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import API from "../../common/API";
import { parsedUser } from "../../common/GetCurrentUser";
import IsLogedIn from "../../common/IsLogedIn";
import { Routes } from "../../routes";
import DeviceDetails from "./DeviceDetails";
const Devices = () => {
  const [devices, setDevices] = useState([]);

  const history = useHistory();

  //Device related
  const requestDevices = async () => {
    let user = parsedUser();
    let request = await API.getAction(
      "device/forAccount?id=" + user.id,
      null,
      null
    );
    console.log("Response devices request", request);
    if (request.data.status === "ok") {
      setDevices(request.data.response);
    }
  };

  useEffect(() => {
    if (IsLogedIn()) {
      requestDevices();
    }
  }, []);
  const toggleShowDetails = (obj) => {
    history.push(Routes.DevicesDetails.clean + obj.id);
  };
  return (
    <>
      <Container>
        <Row className="mx-0">
          {/* <Col xs={2} className="text-right mt-4">
                    <button className='btn btn-sm btn-primary'>
                        <i className='fa fa-plus'></i>
                    </button>
                </Col> */}
          <Col xs={12} className="mx-auto">
            {devices && devices.length > 0 ? (
              devices.map((device, index) => {
                return (
                  <Card
                    className="shadow-sm clickable bg-light mb-2"
                    key={index}
                    onClick={() => {
                      toggleShowDetails(device);
                    }}
                  >
                    <Card.Body className="p-1">
                      {console.log(device)}
                      <div className="row mx-0">
                        <div
                          className={
                            "col-auto text-center px-2  pt-2 " +
                            (device.status === "online"
                              ? "text-success"
                              : "text-danger")
                          }
                        >
                          {device.category === "car" ? (
                            <span className="fa fa-car fa-2x"></span>
                          ) : (
                            <i className="fas fa-map-marker-alt"></i>
                          )}
                        </div>

                        <div className="col">
                          <span>{device.name}</span>
                          <small className="text-muted ">
                            &nbsp;{moment(device.lastUpdate).fromNow()}
                          </small>
                          <br />
                          <small className=" badge bg-info">
                            Device Type: {device.deviceType.name ?? "-"}
                          </small>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                );
              })
            ) : (
              <p className="text-center my-4">No hay contenido</p>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Devices;
