import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row, Card, Container, Dropdown, Nav } from "react-bootstrap";
import PinComponent from "../Common/PinComponent";

import { Routes } from "../../routes";

import API from "../../common/API";
import Settings from "../../common/Settings";
import GoogleMapReact from "google-map-react";

import moment from "moment-timezone";
import { TraccarSocket } from "../../Context/TraccarSocket";

import { parsedUser } from "../../common/GetCurrentUser";
import Devices from "../Devices/Devices";
import { userAvatar } from "../../common/helpers";
import DeviceDetails from "../Devices/DeviceDetails";

const DashboardOverview = () => {
  let socket = useContext(TraccarSocket);
  if (socket) {
    socket.onmessage = (data, event) => {
      console.log("Enter on message socket from dashboard", data, event);
    };
  }
  const history = useHistory();
  const user = parsedUser();
  const GoogleMapRef = React.createRef();
  const [pins, setPins] = useState([]);
  const [deviceSelected, setDeviceSelected] = useState(null);
  const [zoom, setZoom] = useState(17);
  const [state, setState] = useState({
    center: { lat: 18.4718609, lng: -69.8923187 },
    scale: {
      sm: 1,
      md: 2.5,
      lg: 3.5,
      xl: 4.5,
      xxl: 6.5,
    },
    Devices: [],
    Pins: [],

    DeviceSelected: null,
    Events: [],
  });
  const [theMap, setTheMap] = useState(null);
  const [theMapsReference, setTheMapsReference] = useState(null);
  const handleApiLoaded = (map, mapsReference) => {
    setTheMap(map);
    setTheMapsReference(mapsReference);
  };

  //Device related

  const requestDevices = async () => {
    if (!user) {
      history.push(Routes.SignIn.path);
      return;
    }
    let userId = user.id;

    let header = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    let request = await API.getAction(
      "device/positions?userid=" + userId,
      null,
      header
    );

    if (request.data.status === "ok") {
      setPins(request.data.response);
    }
  };


  //End device related
  const centerMap = () => {
    let withMarker = false;
    if (theMapsReference) {
      const bounds = new theMapsReference.LatLngBounds();

      pins.forEach((marker) => {
        if (marker.positionInfo && marker.positionInfo.latitude) {
          console.log("point", marker);
          withMarker = true;
          bounds.extend(
            new theMapsReference.LatLng(
              marker.positionInfo.latitude,
              marker.positionInfo.longitude
            )
          );
        }
      });
      if (withMarker) {
        theMap.fitBounds(bounds);
        //theMap.setZoom(zoom);
        console.log("bounds", bounds, zoom);
      }
    }
  };
  useEffect(() => {
    if (!state.fromInterval) {
      centerMap();
    }
  }, [pins, theMapsReference]);

  useEffect(() => {
    // if (!IslogedIn()) {
    //  history.push(Routes.SignIn.path);
    // }
    requestDevices();
  }, []);

  useEffect(() => {
    let intervalId = null;

    const onFocus = () => {
      intervalId = setInterval(() => {
        setState({ ...state, fromInterval: true });
        requestDevices();
      }, 5000);
    };
    const onBlur = () => {
      clearInterval(intervalId);
    };

    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);

    if (intervalId === null) {
      onFocus();
    }

    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
      clearInterval(intervalId);
    };
  }, []);

  const onHandleZoom = (obj) => {
    console.log(obj);
    setZoom(obj.zoom);
  };

  const [showDetails, setShowDetails] = useState(false);
  const toggleDetails = item => {
    setDeviceSelected(item);
    setShowDetails(!showDetails);
  }
  const [showMenu, setShowMenu] = useState(true);
  return (
    <>
      {showDetails ? <DeviceDetails id={deviceSelected.id} show={showDetails} toggle={toggleDetails} /> : null}
      <GoogleMapReact
        ref={GoogleMapRef}
        // onClick={this.handleClick}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) =>
          handleApiLoaded(map, maps)
        }
        bootstrapURLKeys={{ key: Settings.GoogleMapsAPIKey }}
        style={{
          width: "100%",
          minHeight: "300px",
          height: "100vh",
          position: "relative",
        }}
        //id={this.props.Element.nombreCampo}
        center={{ lat: state.center.lat, lng: state.center.lng }}
        defaultZoom={zoom}
        onChange={(e) => onHandleZoom(e)}
      >
        {console.log(zoom)}
        {pins
          .filter((x) => x.positionInfo)
          .map((item, index) => {
            console.log(item);
            return (
              <PinComponent
                model={item}
                key={index}
                lat={item.positionInfo.latitude}
                lng={item.positionInfo.longitude}
                zoom={zoom}
                title={item.name}
                scale={state.scale}
                onClick={toggleDetails}
              />
            );
          })}
      </GoogleMapReact>
      <div className="OptionsContainer border-end shadow-sm bg-white col-12 col-md-5 col-lg-3">
        <Row className="my-2 mx-0">
          <Col className="text-start">

            <button className="btn btn-sm me-2" onClick={() => setShowMenu(!showMenu)}>
              <i className="fas fa-bars"></i>
            </button>
            <button className="btn btn-sm btn-primary">
              <i className="fa fa-users"></i>
              <br />
              <small>Users</small>
            </button>

            <button className="btn btn-sm ms-2">
              <i className="fa fa-car"></i>
              <br />
              <small>Devices</small>
            </button>
          </Col>
          <Col xs={'auto ml-auto me-2'}>
            <Dropdown >
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <img
                    src={userAvatar(user && user.email, 40)}
                    className="user-avatar rounded"
                    alt="User"
                  />
                  {/* <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">
                      {user && user.email}
                    </span>
                  </div> */}
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                {/* <Dropdown.Item
                    className="fw-bold"
                    onClick={() => props.history.push(Routes.Profile.path)}
                  >
                    <FontAwesomeIcon icon={faUserCircle} className="me-2" />{" "}
                    Perfil
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="fw-bold d-none"
                    onClick={() => props.history.push(Routes.Settings.path)}
                  >
                    <FontAwesomeIcon icon={faCog} className="me-2" />{" "}
                    Configuración
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold d-none">
                    <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" />{" "}
                    Messages
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="fw-bold"
                    as="button"
                    onClick={() => {
                      //  setShowAddEditProject(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faUserShield} className="me-2" />{" "}
                    Support
                  </Dropdown.Item>

                  <Dropdown.Divider />
                  */}
                <Dropdown.Item
                  className="fw-bold"
                  onClick={() => {
                    history.push(Routes.SignIn.path);
                  }}
                >
                  <i className="fa fa-sign-out-alt text-danger me-2"></i>{" "}
                  Cerrar Sesión
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs={12} className={`${!showMenu ? "d-none" : ""}`}>
            <hr />
            <Devices></Devices>
          </Col>
        </Row>
      </div>

    </>
  );
};

export default DashboardOverview;
