import { createContext } from 'react';

import API from '../common/API';
import qs from 'qs';
import Settings from '../common/Settings';

export const TraccarSocket = ExecuteOnStart => {
  const generatesession = async () => {
    //First the session 
    let headersTraccar = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    };
    let query = qs.stringify({ 'email': 'info@valegos.com', 'password': 'Valego2015@' });
    // let request = await API.getActionExternal(process.env.TRACCAR_SERVER + "/api/server", {}, headersTraccar);
    let session = await API.postActionExternal(`https://${Settings.BasePathTracker}/api/session`, query, headersTraccar);
  }


  const start = () => {
    generatesession();
    const socket = new WebSocket(`${window.location.href.indexOf("http:") > -1 ? "wss" : "wss"}://${Settings.BasePathTracker}/api/socket`);
    // Listen for possible errors
    socket.addEventListener('error', (event) => {
      console.log('WebSocket error: ', event);
    });




    socket.onopen = () => {
      console.log("on open");
    };


    socket.onclose = function (e) {
      console.log('Socket is closed. Reconnect will be attempted in 5 second.', e.reason);
      // setTimeout(function () {
      //   start();
      //   socketTimeoutBase += 1;
      // }, 5000 * socketTimeoutBase);
    };
    socket.onmessage = (data, event) => {
      console.log("Enter on message socket", data, event)

      let parsed = JSON.parse(data.data);

    }

    socket.onerror = function (err) {

      console.error('Socket encountered error: ', err);
      socket.close();
    };
  }

  start();

}

export const SocketContext = createContext();