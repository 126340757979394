export const calculateAutoSize = (scale, width, height, zoom) => {
  let result = 0;
  result = height / width;
  result = result * zoom * scale;
  return Math.round(result);
};
const autoSizePin = (scale, zoom) => {
  let width = window.innerWidth;
  let height = window.innerHeight;
  let scaleSelected;

  if (width < 577) {
    scaleSelected = scale.sm; //Controls the pin size scale
  } else if (width > 576 && width < 769) {
    scaleSelected = scale.md; //Controls the pin size scale
  } else if (width > 768 && width < 1025) {
    scaleSelected = scale.lg; //Controls the pin size scale
  } else if (width > 1024 && width < 1441) {
    scaleSelected = scale.xl; //Controls the pin size scale
  } else {
    scaleSelected = scale.xxl; //Controls the pin size scale
  }
  console.log(calculateAutoSize(scaleSelected, width, height, zoom));
  return calculateAutoSize(scaleSelected, width, height, zoom);
};

export default autoSizePin;
