import { Card, Row, Col, Modal, ListGroup, Tabs } from 'react-bootstrap';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import API from '../../common/API';
import IsLogedIn from '../../common/IsLogedIn';

import { Routes } from '../../routes';

const SMSSender = props => {

    useEffect(() => {


    }, []);

    return (<>

        <Modal show={props.show} onHide={props.toggle} className='modalRight'>
            <Modal.Header closeButton>
                <Modal.Title>Mensaje</Modal.Title>

            </Modal.Header>
            <Modal.Body>
                <Row>
                    {props.model.Note ?
                        <Col xs={12} className="mb-2">
                            <div dangerouslySetInnerHTML={{ __html: props.model.Note }}>
                            </div>
                        </Col> : null}
                    <Col xs={12}>

                        <a href={props.model.SMS} className="btn btn-block btn-primary">Enviar mini-mensaje</a>
                        <button className='btn btn-block btn-secondary mt-2' disabled>Enviar desde plataforma</button>


                    </Col>

                    <Col xs={12} className="mt-2">
                        <ul>
                            <li>Para recibir el mensaje asegurese de estar en el mismo operador de servicios  (Claro/Altice/etc)</li>
                            <li>Algunos mensajes pueden tardar en retornar respuesta</li>
                        </ul>
                    </Col>
                </Row>
            </Modal.Body>

        </Modal>
    </>);
}

export default SMSSender;