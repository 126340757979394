import React, { useEffect } from "react";
import { Modal, Button, Col, Row, Form } from "react-bootstrap";
const AddEditGeoFence = (props) => {
  const AddEdit = () => {
    let query = {
      id: 0,
      name: "string",
      description: "string",
      area: "string",
      calendarId: 0,
      attributes: {},
    };
  };
  /* useEffect(() => {
    state.set(props.model !== null ?
      JSON.parse(JSON.stringify(props.model.value))
      : null);
    
  }, []); */
  return (
    <Modal show={props.show} onHide={props.toggle} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Geofence</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Geofence Name" />
          </Col>
          <Col xs={12}>
            <Form.Label>Description</Form.Label>
            <Form.Control as="textarea" placeholder="Geofence Name" />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.toggle} className="btn-sm">
          Close
        </Button>
        <Button variant="primary" onClick={props.toggle} className="btn-sm">
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddEditGeoFence;
