import React from "react";
const Pagination = (props) => {
  let pageNumbers = [];
  for (let i = 0; i < props.totalPages; i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = () => {
    if (props.totalPages > 10) {
      pageNumbers = [];
      //We need to split this result and generate it again
      if (props.currentPage + 9 > props.totalPages) {
        for (let i = props.totalPages - 10; i <= props.totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        for (let i = props.currentPage; i <= props.currentPage + 9; i++) {
          pageNumbers.push(i);
        }
      }
    }
    return pageNumbers.map((number) => {
      return (
        <li
          className={
            props.currentPage === number
              ? "page-item mx-2 px-2 active"
              : "page-item mx-2 px-2"
          }
          key={number}
          id={number}
        >
          <button
            className={
              (props.currentPage === number ? "btn-primary " : "") + "btn"
            }
            onClick={() => {
              props.onPageChanged(number);
            }}
          >
            {number + 1}
          </button>
        </li>
      );
    });
  };

  return (
    <div id="pgcion">
      <ul className="pagination pg-blue">
        {props.currentPage > 1 ? (
          <li className="page-item mx-2 px-2">
            <button
              className="btn"
              type="button"
              onClick={() => props.onPageChanged(1)}
            >
              &laquo;&laquo;
            </button>
          </li>
        ) : null}

        {props.currentPage > 1 ? (
          <li className="page-item mx-2 px-2">
            <button
              className="btn"
              type="button"
              onClick={() => props.onPageChanged(props.currentPage - 1)}
            >
              &laquo;
            </button>
          </li>
        ) : null}

        {renderPageNumbers()}

        {props.currentPage < props.totalPages ? (
          <li className="page-item mx-2 px-2">
            <button
              className="btn"
              type="button"
              onClick={() => props.onPageChanged(props.currentPage + 1)}
            >
              &raquo;
            </button>
          </li>
        ) : null}
        {props.currentPage < props.totalPages ? (
          <li className="page-item mx-2 px-2">
            <button
              className="btn"
              type="button"
              onClick={() => props.onPageChanged(props.totalPages)}
            >
              &raquo;&raquo;
            </button>
          </li>
        ) : null}
      </ul>
    </div>
  );
};
export default Pagination;
