import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../../css/corporate.css";
import { Routes } from "../../routes";
import principalImg from "../../assets/img/principallstracking.png";
import productsImg from "../../assets/img/333shots_so.png";
import socialImg from "../../assets/img/social.svg";
import { parsedUser } from "../../common/GetCurrentUser";

//Translation
import { useTranslation } from "react-i18next";
import Header from "./Header";
import Footer from "./Footer";

const Corporate = () => {
  const history = useHistory();
  const user = parsedUser();

  const { t } = useTranslation("global");
  useEffect(() => {
    if (user) {
      history.push(Routes.Dashboard.path)
    }
  }, [])

  return (
    <div className="corporate">
      <Header />

      <header className="masthead" id="home">
        <div className="container px-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <div className="mb-5 mb-lg-0 text-center text-lg-start">
                <h1 className="display-1 lh-1 mb-3">
                  Logistic Solution Tracker
                </h1>
                <p className="lead fw-normal text-muted mb-5">
                  Rastrea tu móvil, tu flota y tu negocio con la mejor tecnología GPS.
                </p>
                <div className="d-flex flex-column flex-lg-row align-items-center">
                  <a className="btn btn-primary" href={Routes.SignIn.path}>
                    Contactanos
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="masthead-device-mockup">
                <img src={principalImg}></img>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- Quote/testimonial aside--> */}
      <aside className="text-center bg-gradient-primary-to-secondary">
        <div className="container px-5">
          <div className="row gx-5 justify-content-center">
            <div className="col-xl-8">
              <div className="h2 fs-1 text-white mb-4">
                "Una solución efectiva de rastrear remotamente sus vehículos y activos de una manera simple pero poderosa"
              </div>
              {/* <img src="assets/img/tnw-logo.svg" alt="..." style="height: 3rem" /> */}
            </div>
          </div>
        </div>
      </aside>
      {/* <!-- App features section--> */}
      <section id="features">
        <div className="container px-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-8 order-lg-1 mb-5 mb-lg-0">
              <div className="container-fluid px-5">
                <div className="row gx-5">
                  <div className="col-md-6 mb-5">
                    {/* <!-- Feature item--> */}
                    <div className="text-start">
                      <i className="bi-phone icon-feature text-gradient d-block mb-3"></i>
                      <h3 className="font-alt">Multi-cuentas</h3>
                      <p className="text-muted mb-0">
                        Puede tener el control de toda su flotilla o asignarle una cuenta a un personal en particular
                      </p>
                      {/* <p>
                        <button
                          className="btn btn-primary btn-sm mt-2"
                          onClick={() => {
                            history.push(Routes.Invoicing.path);
                          }}
                        >
                          Leer más
                        </button>
                      </p> */}
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    {/* <!-- Feature item--> */}
                    <div className="text-start">
                      <i className="bi-camera icon-feature text-gradient d-block mb-3"></i>
                      <h3 className="font-alt">Notificaciones</h3>
                      <p className="text-muted mb-0">
                        Mantenga el control de sus dispositivos con notificaciones personalizadas, ubicación, batería baja, entre otras.
                      </p>
                      {/* <p>
                        <button className="btn btn-primary btn-sm mt-2">
                          Leer más
                        </button>
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-5 mb-md-0">
                    {/* <!-- Feature item--> */}
                    <div className="text-start">
                      <i className="bi-gift icon-feature text-gradient d-block mb-3"></i>
                      <h3 className="font-alt">Reportes</h3>
                      <p className="text-muted mb-0">
                        Monitoree el historico del dispositivo, paradas, excesos de velocidad y más.
                      </p>
                      {/* <p>
                        <button className="btn btn-primary btn-sm mt-2">
                          Leer más
                        </button>
                      </p> */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/* <!-- Feature item--> */}
                    <div className="text-start">
                      <i className="bi-patch-check icon-feature text-gradient d-block mb-3"></i>
                      <h3 className="font-alt">Geocercas</h3>
                      <p className="text-muted mb-0">
                        Establezca zonas de interes que necesite saber cuando su dispositivo entra o sale del area.
                      </p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 order-lg-0">
              {/* <!-- Features section device mockup--> */}
              <div className="features-device-mockup">
                <img src={productsImg} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Basic features section--> */}
      <section className="bg-light d-none">
        <div className="container px-5">
          <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
            <div className="col-12 col-lg-5">
              <h2 className="display-4 lh-1 mb-4">
                Enter a new age of web design
              </h2>
              <p className="lead fw-normal text-muted mb-5 mb-lg-0">
                This section is perfect for featuring some information about
                your application, why it was built, the problem it solves, or
                anything else! There's plenty of space for text here, so don't
                worry about writing too much.
              </p>
            </div>
            <div className="col-sm-8 col-md-6">
              <div className="px-5 px-sm-0">
                <img
                  className="img-fluid rounded-circle"
                  src="https://source.unsplash.com/u8Jn2rzYIps/900x900"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Call to action section--> */}
      <section className="cta d-none ">
        <div className="cta-content">
          <div className="container px-5">
            <h2 className="text-white display-1 lh-1 mb-4">
              {t("corporate.stop_waiting")}.
              <br />
              {t("corporate.start_building")}.
            </h2>
            <a
              className="btn btn-outline-light py-3 px-4 rounded-pill"
              href="https://startbootstrap.com/theme/new-age"
              rel="noreferrer"
              target="_blank"
            >
              {t("corporate.free_download")}.
            </a>
          </div>
        </div>
      </section>
      {/* WHY PLEELO */}
      <section className="bg-gradient-primary-to-secondary" id="about">
        <div className="container px-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <h2 className="text-white">¿Por qué LS Tracking?</h2>
              <p className="text-white">
                Es una plataforma moderna y flexible que continúa constantemente evolucionando, gracias a que el equipo se mantiene en las nuevas tecnologías capacitando. Queremos brindar paz mental a la industria de rastreo de dispositivos GPS con una solución sencilla y práctica.
              </p>
              <p className="text-white mt-4">
                La idea es que pueda enfocarse en su negocio y descargar esa carga logística de rastreo en nosotros.


              </p>
            </div>
            <div className="col-lg-6">
              <div className="masthead-device-mockup">
                <img src={socialImg}></img>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer></Footer>
    </div>
  );
};

export default Corporate;
