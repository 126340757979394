import React, { useState } from 'react';

import { Modal, Button, Col, Row, Form } from 'react-bootstrap';

import * as yup from 'yup';
import { Formik, useFormik } from 'formik';


import GoogleMapReact from 'google-map-react';
import moment from 'moment';


import { v4 as uuidv4 } from 'uuid';

import API from '../../common/API';
import { parsedUser } from '../../common/GetCurrentUser';
import Settings from '../../common/Settings';
import PinComponent from '../Common/PinComponent';
import { useEffect } from 'react';
import MapHelper from '../../common/MapHelper';


//Validation
const validationSchema = yup.object({
    //    //   id: yup.string().required(),
    //   from: yup.string().required(),
    //    organizationId: yup.string(),

    //to: yup.number().required()


});
//Component
const Trips = props => {

    const user = parsedUser();
    const [state, setState] = useState({
        center: { lat: 18.4718609, lng: -69.8923187 },
        zoom: 11,

        DeviceSelected: null,
        Events: [],
        UI: {
            showAddEditGeofence: false,
            GeofenceSelected: null
        }
    });

    const GoogleMapRef = React.createRef();
    const [results, setResults] = useState([]);

    const handleSubmit = async (obj, event) => {
        console.log("values to submit", obj);
 console.log("dates", moment(obj.from).utc().format(), moment(obj.to).utc().format())
        let query = `reports/trips?userId=${user.id}&deviceId=${props.model.device.id}`;
        if (obj.from) {
            query = query + "&from=" + moment(obj.from).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
        }
        if (obj.to) {
            query = query + "&to=" + moment(obj.to).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
        }
        let request = await API.getAction(query);

        setResults(request.data);

    };
    const formik = useFormik({
        initialValues: {
            from: moment().format(),
            to: moment().format()
        },
        validationSchema,
        onSubmit: handleSubmit,

    });
    const [theMap, setTheMap] = useState(null);
    const [theMapsReference, setTheMapsReference] = useState(null);
    const handleApiLoaded = (map, mapsReference) => {
        setTheMap(map);
        setTheMapsReference(mapsReference);
    }



    useEffect(() => {
        if (results.length > 0) {
            results.forEach(item => {
                let from = {
                    latitude: item.startLat,
                    longitude: item.startLon
                }
                let end = {
                    latitude: item.endLat,
                    longitude: item.endLon
                }
                if (theMapsReference) {
                    MapHelper.fromToRoute(theMap, theMapsReference, from, end)
                }
            })
        }
    }, [results, theMapsReference])

    if (!props.model) {
        return (<></>)
    }
    return (
        <Modal show={props.show} onHide={props.toggle} size='md' className='modalRight'>
            <Modal.Header closeButton>
                <Modal.Title>Viajes</Modal.Title>

            </Modal.Header>



            <Form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    {/* 
                LEAVE IT HERE SO WE CAN PROCCEED TO TROUBLESHOOT ANYTHING
                {Object.values(formik.errors).map((item, index)=>{
                    return(item);
                })} */}
                    <Row className='mx-0'>
                        <Col xs={5} >
                            <Form.Label>Desde</Form.Label>

                            <input type="datetime-local"
                                name="from"
                                className='form-control'
                                value={formik.values.from ?? ""} onChange={formik.handleChange} />
                            {formik.errors.from ? <div className="invalid text-sm">{formik.errors.from}</div> : null}
                        </Col>
                        <Col xs={5} >
                            <Form.Label>Hasta</Form.Label>
                            <input type="datetime-local"
                                name="to"
                                className='form-control'
                                value={formik.values.to ?? ""} onChange={formik.handleChange} />
                            {formik.errors.to ? <div className="invalid text-sm">{formik.errors.to}</div> : null}
                        </Col>
                        <Col xs={2}>

                            <Form.Label>&nbsp;</Form.Label>
                            <br />
                            <button className='btn btn-sm btn-primary' type="submit">
                                <i className='fa fa-search'></i>
                            </button>
                        </Col>
                        {results.length > 0 ?
                            <Col xs={12}>
                                <GoogleMapReact ref={GoogleMapRef}
                                    // onClick={this.handleClick}
                                    yesIWantToUseGoogleMapApiInternals={true}
                                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                                    bootstrapURLKeys={{ key: Settings.GoogleMapsAPIKey }}
                                    style={{ width: "100%", minHeight: "300px", height: "300px", position: "relative" }}
                                    //id={this.props.Element.nombreCampo}
                                    //defaultCenter={{ lat: state.center.lat.value, lng: state.center.lng.value }}
                                    center={{ lat: state.center.lat, lng: state.center.lng }}
                                    //     center={state !== undefined && state.center !== undefined && state.center !== null? state.center.value: null}
                                    defaultZoom={17}
                                >

                                </GoogleMapReact>

                                {results.map((item, index) => {

                                    return (
                                        <div className='p-1 bg-light shadow-sm mt-2' key={index}>
                                            {moment(item.startTime).format("MMM DD, YYYY")}<br />
                                            <small className='text-muted'>
                                                {moment(item.startTime).format("hh:mm:ss a")}  {item.startAddress}
                                            </small><br />
                                            {moment(item.endTime).format("MMM DD, YYYY")}<br />
                                            <small className='text-muted'>
                                                {moment(item.endTime).format("hh:mm:ss a")} {item.endAddress}
                                            </small>
                                        </div>
                                    )
                                })}
                            </Col>
                            : null}


                    </Row>

                </Modal.Body >

            </Form >

        </Modal >
    )
}

export default Trips;