import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "./routes";
import { SocketContext, TraccarSocket } from "./Context/TraccarSocket";

// pages
import Home from "./pages/home";

import DevicesPage from "./pages/DevicesPage";
import Users from "./pages/users";

import Profile from "./pages/profile";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";

import Settings from "./pages/settings";
import Geofences from "./pages/geofences";

// components
import Sidebar from "./components/Common/Sidebar";
import Navbar from "./components/Common/Navbar";
import Preloader from "./components/Preloader";
import DeviceDetails from "./components/Devices/DeviceDetails";
import PermissionNotification from "./components/Settings/PermissionNotification";
import Corporate from "./components/Corporate/Corporate";
import IsLogedIn from "./common/IsLogedIn";
import { parsedUser } from "./common/GetCurrentUser";
import PasswordRecovery from "./components/Profile/PasswordRecovery";
import { Container } from "react-bootstrap";

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  const history = useHistory();

  /*const warningNotification = () =>{
    addNotification({
      title:'Solicitud de Permiso',
      message: '¿Desea recibir notificaciones de esta web?',
      closeButton: 'x',
      native: true,
      duration: 5000
    })
  }*/

  useEffect(() => {
    if (!IsLogedIn()) {
      history.push(Routes.SignIn.path);
    }

    const timer = setTimeout(() => setLoaded(true), 1000);

    //warningNotification();
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem("settingsVisible") === "false" ? false : true;
  };

  const [showSettings, setShowSettings] = useState(
    localStorageIsSettingsVisible
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem("settingsVisible", !showSettings);
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={loaded ? false : true} />


          <Container fluid className="p-0">
            <Component {...props} />


          </Container>
        </>
      )}
    />
  );
};

export default () => {
  const socket = TraccarSocket(() => { });

  return (
    <SocketContext.Provider value={socket}>
      <Switch>
        {/* pages */}
        <Route path={Routes.Home.path} component={Corporate} exact />
        <RouteWithSidebar exact path={Routes.Dashboard.path} component={Home} />
        <RouteWithSidebar
          exact
          path={Routes.Settings.path}
          component={Settings}
        />
        <RouteWithSidebar
          exact
          path={Routes.Devices.path}
          component={DevicesPage}
        />
        <RouteWithSidebar exact path={Routes.Users.path} component={Users} />
        <RouteWithSidebar
          exact
          path={Routes.DevicesDetails.path}
          component={DeviceDetails}
        />

        <RouteWithSidebar
          exact
          path={Routes.Geofences.path}
          component={Geofences}
        />

        <RouteWithSidebar
          exact
          path={Routes.Profile.path}
          component={Profile}
        />

        <Route path={Routes.SignIn.path} component={Login} exact />
        <Route path={Routes.Register.path} component={Register} />
        <Route path={Routes.ForgotPassword.path} component={ForgotPassword} />

        <Route
          path={Routes.PasswordRecovery.path}
          component={PasswordRecovery}
        />
        <Redirect to={Routes.NotFound.path} />
      </Switch>
    </SocketContext.Provider>
  );
};
