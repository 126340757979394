import React from 'react';
import GeofencesComponent from '../components/Geofences/Geofences';

const Geofences = props => {
  return (
    <GeofencesComponent/>
  )
}

export default Geofences;
